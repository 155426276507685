.intmath1-upgrade {
  width: 100%;

  .intmath1-inner {
    max-width: 450px;
    width: calc(100% - 30px);
    margin-top: calc(120vw * 100 / 1920);
    margin-left: auto;
    margin-right: auto;
  }

  h4 {
    font-size: 17.5px;
    font-weight: 600;
    line-height: 120%;
    color: #495057;
  }

  .intmath1-plans {
    display: flex;
    align-items: flex-end;
    margin-top: calc(50vw * 100 / 1920);
    margin-bottom: calc(26vw * 100 / 1920);
    margin-left: -18px;
    margin-right: -18px;
  }

  .intmath1-plan {
    flex: 1;
    margin: 0 18px;

    .intmath1-deal {
      text-align: center;

      .intmath1-deal-best {
        display: inline-block;
        padding: 4px 24px;
        font-size: 12px;
        background: #ffe600;
      }
    }

    .intmath1-plan-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 0 18px;
      padding: 17.5px;
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12),
        0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.02);
      border-radius: 6px;
    }
  }

  .intmath1-plan-price {
    font-weight: 300;
    font-size: 16px;
    line-height: 106%;

    .current-price,
    .sales-price,
    .original-price {
      .price {
        font-size: 20px;
        font-weight: 700;
        line-height: 85%;
      }
    }

    .sales-price .price {
      color: #fc0000;
    }

    .original-price {
      color: #d9d9d9;

      span {
        text-decoration-line: line-through;
      }
    }
  }

  ul.intmath1-plan-features {
    margin: 0;
    margin-top: 30px;
    padding: 0;
    list-style: none;

    li {
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        background: url("../images/icon-check-circle.svg") no-repeat center
          center;
        background-size: contain;
      }

      margin-bottom: 24px;
      font-weight: 300;
      font-size: 16px;
      line-height: 106%;
    }
  }

  .intmath1-btn {
    display: block;
    width: 100%;
    padding: 10px 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 106%;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background: #3b82f6;
    border: 1px solid #3b82f6;
    border-radius: 6px;
  }
}

@media screen and (max-width: 991px) {
  .intmath1-upgrade {
    .intmath1-plans {
      margin-top: 50px;
      margin-bottom: 26px;
    }
  }
}

@media screen and (max-width: 420px) {
  .intmath1-upgrade {
    h4 {
      font-size: 16px;
    }

    .intmath1-plans {
      margin-left: -5px;
      margin-right: -5px;

      .intmath1-plan {
        margin: 0 5px;

        .intmath1-plan-inner {
          padding: 10px 5px;
        }
      }
    }

    ul.intmath1-plan-features {
      li {
        font-size: 14px;

        &:before {
          width: 15px;
          height: 15px;
        }
      }
    }

    .intmath1-btn {
      font-size: 15px;
    }
  }
}
