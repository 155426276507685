body {
  --keycap-height: 40px;
  --keycap-gap: 6px;
  --keyboard-toolbar-font-size: 16px;
  --keycap-font-size: 18px;
}

.react-chatbot-kit-chat-container {
  width: 100%;
  height: 100vh;

  .react-chatbot-kit-chat-inner-container {
    padding: 3px;
  }

  .react-chatbot-kit-chat-header {
    display: none;
  }

  .intmath1-image {
    img {
      max-width: 336px;
      max-height: 336px;
      object-fit: contain;
    }
  }

  a {
    color: inherit;
  }

  .intmath1-system-message-container {
    padding-top: 80px;

    .intmath1-system-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      line-height: 142%;

      h3 {
        font-size: 24px;
        line-height: 120%;
      }
    }
  }

  .react-chatbot-kit-user-chat-message:not(.media) {
    padding: 0 15px;
    font-size: 16px;
  }

  .react-chatbot-kit-chat-bot-message {
    padding: 0 15px;
    font-size: 14px;

    .chatbot-loader-container {
      margin: 1em 0;
    }
  }

  .iframe-container {
    display: none;
    position: relative;
    max-width: 576px;
    width: 100%;
    aspect-ratio: 0.9; /* PDF Viewer has toolbar */
    margin: 10px 0;

    &.show {
      display: block;
    }

    &.pdf-container {
      aspect-ratio: 1 / 1; /* PDF Viewer has no toolbar */
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  button.intmath1-btn,
  a.intmath1-btn {
    display: inline-block;
    max-width: 100%;
    margin: 5px 5px 5px 0;
    padding: 0 15px;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #ff6c01;
    border: 1px solid #ff6c01;
    border-radius: 6px;
    cursor: pointer;
  }

  .graph-button {
    display: none;
    min-width: 180px;

    &.show {
      display: block;
    }
  }

  .intmath1-btn-submit {
    width: 140px;
    font-size: 20px;
  }

  #input {
    width: calc(100% - 52px - 140px);
  }

  math-field {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  }

  math-field::part(virtual-keyboard-toggle) {
    display: none !important;
  }

  math-field::part(container) {
    height: 100%;
  }

  #keyboard {
    .ML__keyboard.is-visible {
      height: var(--_keyboard-height);
    }
  }
}
