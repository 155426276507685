.intmath1-auth {
  width: 100%;
  height: 100vh;
  border: 1px solid #3b82f6;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 2.8px #bfdbfe;

  .intmath1-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    width: calc(100% - 30px);
    height: 100%;
    margin: 0 auto;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    color: #495057;
  }

  .intmath1-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;

    .intmath1-btn {
      display: inline-block;
      width: 100%;
      padding: 10px 12px;
      font-size: 18px;
      font-weight: 700;
      line-height: 106%;
      text-decoration: none;
      text-align: center;
      color: #fff;
      background: #3b82f6;
      border: 1px solid #3b82f6;
      border-radius: 6px;
    }

    h6 {
      margin-top: 20px;
      color: #515867;
    }
  }
}

@media screen and (max-width: 991px) {
  .intmath1-upgrade {
    .intmath1-plans {
      margin-top: 50px;
      margin-bottom: 26px;
    }
  }
}

@media screen and (max-width: 420px) {
  .intmath1-upgrade {
    h4 {
      font-size: 16px;
    }

    .intmath1-plans {
      margin-left: -5px;
      margin-right: -5px;

      .intmath1-plan {
        margin: 0 5px;

        .intmath1-plan-inner {
          padding: 10px 5px;
        }
      }
    }

    ul.intmath1-plan-features {
      li {
        font-size: 14px;

        &:before {
          width: 15px;
          height: 15px;
        }
      }
    }

    .intmath1-btn {
      font-size: 15px;
    }
  }
}
