// @import "react-chatbot-kit/build/main.css";
@import "components/Test1/main.css";

@import "assets/styles/functions.scss";
@import "assets/styles/common.scss";
@import "assets/styles/header.scss";
@import "assets/styles/solver.scss";
@import "assets/styles/paywall.scss";

body {
  overflow: hidden;
}
