.intmath1-chat-header {
  // display: flex;
  // align-items: center;
  // padding: 12.5px;
  // font-weight: bold;
  border-bottom: 1px solid #b1b1b1;
  // border-top-right-radius: 5px;
  // border-top-left-radius: 5px;

  .nav-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // max-width: 1200px;
    // margin: 0 auto;
    padding: 0 12px;
  }

  .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu-items {
    position: relative;
    font-size: 14px;
  }

  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  button span {
    margin-left: 3px;
  }

  .menu-items > a,
  .menu-items button {
    text-align: left;
    padding: 5px 0;

    img {
      width: 20px;
      height: 20px;

      &.intmath1-hover {
        display: none;
      }
    }

    > span {
      display: flex;
      align-items: center;

      img {
        width: 18px;
        margin-right: 10px;
      }
    }
  }

  .menu-items a:hover,
  .menu-items button:hover {
    color: #f77600;

    > span {
      img {
        display: none;

        &.intmath1-hover {
          display: block;
        }
      }
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    right: 0;
    left: auto;
    z-index: 99;

    min-width: 12rem;
    margin: 0;
    padding: 0;
    // font-size: 0.875rem;
    list-style: none;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .dropdown.show {
    display: block;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .dropdown .menu-items > a {
    padding: 10px;
  }
}
